<template>
    <onboarding-layout
        :title="$t('pages.identity.title')"
        :loading="loading"
        :error-test="errorText"
        :loading-title="$t('global.loadingMessage.verifying')"
    />
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { getNextRoute } from '@/flow/flowController'
    import { verifyApplicant } from '@/services/idology'
    import { localStorageKey, appSessionStorage } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { RouteOption } from '@/flow/flowUtility'
    import originationMixin from '@/mixins/originationMixin'
    import assert from 'assert'
    import { KbaStatus, updateKbaStatus } from '@/services/remoteNotarizationApi'
    import { MiscThanksReasons } from '@/utils/thanksPageHelpers'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        data() {
            return {
                loading: true,
            }
        },
        mixins: [originationMixin],
        mounted: async function () {
            logger.info(`history length: ${window.history.length}`)
            try {
                await this.verify()
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            verify: async function () {
                const verifyApplicantResponse = await verifyApplicant()
                logger.info(`verifyApplicantResponse: ${JSON.stringify(verifyApplicantResponse.data)}`)

                if (verifyApplicantResponse.data.success) {
                    const payload = verifyApplicantResponse.data.payload
                    switch (payload.status) {
                        case KbaStatus.pass: {
                            // Continue with loan application
                            logger.info('User passed verify applicant, proceeding to next route.')
                            return await this.$router.push(getNextRoute(this.$router))
                        }
                        case KbaStatus.allowableFail:
                        case KbaStatus.verifySSN: {
                            logger.info(`User received ${payload.status}, proceeding to next route.`)
                            return await this.$router.replace(getNextRoute(this.$router))
                        }
                        case 'questions': {
                            // show Idology questions view
                            logger.info(`idology questions: ${JSON.stringify(payload)}`)
                            appSessionStorage.setItem(localStorageKey.identityQA, JSON.stringify(payload))
                            return await this.$router.push(getNextRoute(this.$router, RouteOption.identityQuestions))
                        }
                        default:
                            this.errorText = ApiErrorHandler(new Error('postssn - Unknown response.data structure'))
                            break
                    }
                } else if (verifyApplicantResponse.data.error === 'INCOMPLETE_APPLICATION') {
                    logger.info(`verifyIdentity error: ${verifyApplicantResponse.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.identity } })
                } else if (verifyApplicantResponse.data.error === 'OUT_OF_COUNTRY') {
                    logger.info(`verifyIdentity error: ${verifyApplicantResponse.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    await updateKbaStatus(KbaStatus.outOfCountry)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.geo } })
                } else if (verifyApplicantResponse.data.error === 'PRIOR_APPLICATION_FOUND') {
                    if (!verifyApplicantResponse.data.payload.isPriorityApplication) {
                        // Continue with loan application. Don't worry about other active loan applications at this point
                        return await this.$router.push(getNextRoute(this.$router))
                    }
                    assert(verifyApplicantResponse.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${verifyApplicantResponse.data.payload.returnToken2}`)
                    return (window.location.href = `${process.env.VUE_APP_ORIGINATION_BASE_URL}/shared/redirect?p=${verifyApplicantResponse.data.payload.returnToken2}`)
                } else {
                    // Want to skip this page in the event the user goes 'back'
                    await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }
            },
        },
    }
</script>

<style scoped></style>
